.logo {
    text-align: center;
    margin-bottom: 30px;
}

.logo img {
    min-width: 168px;
    max-width: 100%;
    /*min-height: 64px;*/
    max-height: 64px;
    padding: 10px 20px;
}

.stage {
    margin: 24px 16px;
    padding: 24px;
    min-height: 360px;
    background-color: #1f1f1f;
}
