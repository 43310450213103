.title {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    margin: 0 0 5px;
}

.title.production {
    color: #f00;
    background-color: #ff0;
}

.title .warning {
    font-size: 80px;
}
