.main {
    width: 100%;
    text-align: left;
}
.input {
    width: 100%;
    border-bottom: #000 1px solid;
    display: flex;
}
input, select {
    width: 100%;
}
