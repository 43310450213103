.page {
    position: relative;
    margin: 0;
    text-align: center;
    align-content: center;
    justify-content: center;
    overflow: hidden;
    min-width: 320px;
    min-height: calc(100vh - 150px);
}

.console {
    background-color: black;
    height: calc(100vh - 300px);
    width: 100%;
    margin: 0;
    overflow: scroll;
    padding: 2rem;
    color: #aaa;
    font-family: monospace;
    text-align: left;
    white-space: pre-line;
}
